
import { defineComponent, ref, inject } from 'vue';
import { Select, Input, Image } from '@/components/input-elements';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Apollo, Notify } from '@/core/services';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { Actions } from '@/store/enums/StoreEnums';
import InnerLoader from '../../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'add new shipping method',
    components: {
        Input,
        Select,
        Image,
        InnerLoader
    },

    setup(props, context) {
        const emitter: any = inject('emitter');
        const basicFormRef = ref<null | HTMLFormElement>(null);
        const store = useStore();
        const loader = ref(false);
        const i18n = useI18n();
        const locales = ref([]) as Record<any, any>;
        const locale = ref('en');
        const inputFile = ref<null | HTMLFormElement>(null);
        const imageLabel = ref('');
        const image = ref([]) as Record<any, any>;

        // Given Shipping Form Interface
        const basicFormData = ref({
            name: '',
            sub_title: '',
            status: false,
            jsonNameFields: ref({}) as Record<any, any>,
            jsonSubTitleFields: ref({}) as Record<any, any>,
            images: ref([]) as Record<any, any>
        }) as any;

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                basicFormData.value.jsonNameFields[element.locale] = { name: '' };
                basicFormData.value.jsonSubTitleFields[element.locale] = { name: '' };
            });
        };

        loadLang();

        // update on change value input
        const updateValue = (e: string) => {
            basicFormData.value.jsonNameFields[locale.value].name = e;
        };

        const updateSubTitleValue = (e: string) => {
            basicFormData.value.jsonSubTitleFields[locale.value].name = e;
        };

        // On change input value
        const changeLang = (e: string) => {
            basicFormData.value.name = basicFormData.value?.jsonNameFields[e]?.name;
            basicFormData.value.sub_title = basicFormData.value?.jsonSubTitleFields[e]?.name;
        };

        const triggerFile = () => {
            inputFile.value?.click();
        };

        const ImageHandler = async (event: Record<any, any>) => {
            basicFormData.value.images = [];
            if (event.target.files.length == 0) {
                return false;
            }
            loader.value = true;
            const files = event.target.files[0];
            imageLabel.value = files.name;
            const media = event.target.files;
            const response = await store.dispatch(Actions.CREATE_MEDIA, media);
            const resp = JSON.parse(response);
            resp.forEach(element => {
                basicFormData.value.images.push({ src: element.asset_path, id: element.id });
            });
            loader.value = false;
        };

        // Remove Media
        const removeMedia = (index: number, media_id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
                    if (resp == 'true') {
                        basicFormData.value.images.splice(index, 1);
                        image.value.spilce(index, 1);
                        Notify.success(i18n.t('message.MEDIA_REMOVED_SUCCESSFULLY'));
                    }
                    loader.value = false;
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        // Show Media
        const showMedia = async (num: number) => {
            const formData = {
                previewImage: basicFormData.value.images,
                visible: true,
                index: num
            };
            await store.dispatch(Actions.TRIGGER_ACTION, {
                formData: formData,
                type: 'imageProps'
            });
        };

        const validateForm = () => {
            basicFormRef.value?.validate(valid => {
                if (valid) {
                    for (const i in basicFormData.value.jsonNameFields) {
                        if (basicFormData.value?.jsonNameFields[i]?.name == '') {
                            basicFormData.value.jsonNameFields[i].name = basicFormData.value?.name;
                        }
                    }
                    context.emit('store', 'basicFormData', basicFormData?.value);
                    return true;
                }
                return false;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            basicFormRef.value?.resetFields();
        };

        const editShippingMethodValue = (shippingData: Record<any, any>) => {
            resetForm();
            basicFormData.value.images = [];
            if (shippingData.image) {
                basicFormData.value.images.push({
                    src: shippingData.image.file_path,
                    id: shippingData.image.id
                });
            }

            imageLabel.value = 'Image_File';
            basicFormData.value.status = shippingData.status == 1 ? true : false;
            const json_decode_name = JSON.parse(shippingData.name);
            for (const i in json_decode_name) {
                i == 'en' ? (basicFormData.value.name = json_decode_name[i].name) : '';
                basicFormData.value.jsonNameFields[i].name = json_decode_name[i]?.name;
            }
            const json_decode_sub_title = JSON.parse(shippingData.sub_title);
            for (const i in json_decode_sub_title) {
                i == 'en' ? (basicFormData.value.sub_title = json_decode_sub_title[i].name) : '';
                basicFormData.value.jsonSubTitleFields[i].name = json_decode_sub_title[i]?.name;
            }
        };

        return {
            basicFormData,
            basicFormRef,
            inputFile,
            imageLabel,
            locales,
            locale,
            loader,
            updateValue,
            changeLang,
            triggerFile,
            ImageHandler,
            editShippingMethodValue,
            validateForm,
            loadLang,
            resetForm,
            updateSubTitleValue,
            showMedia,
            removeMedia
        };
    }
});
