import { gql } from "graphql-tag";
import { CATEGORY_FIELDS } from "@/modules/catalog/categories/graphql/Fragments";

export const CREATE_SHIPPING_METHOD = gql`
	mutation CreateShippingMethod($input: ShippingMethodInput!) {
		create_shipping_method(shipping_method: $input)
	}
`;

export const UPDATE_SHIPPING_METHOD = gql`
	mutation UpdateShippingMethod($input: ShippingMethodInput!) {
		update_shipping_method(shipping_method: $input)
	}
`;

export const UPDATE_SHIPPING_POSITION = gql`
	mutation UpdateShippingPosition($input: UpdateShippingMethodPositionInput!) {
		update_shipping_position(positions: $input)
	}
`;

export const DELETE_SHIPPING_METHOD = gql`
	mutation DeleteShippingMethod($id: Int!) {
		delete_shipping_method(id: $id)
	}
`;

export const COPY_SHIPPING_METHOD= gql`
    mutation CopyShippingMethod($shipping_method_id: String) {
        copy_shipping_method(shipping_method_id:$shipping_method_id)
        }
`;

export default { CREATE_SHIPPING_METHOD, UPDATE_SHIPPING_METHOD, UPDATE_SHIPPING_POSITION, COPY_SHIPPING_METHOD, DELETE_SHIPPING_METHOD };
