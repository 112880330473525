
import { defineComponent, ref, inject, onMounted } from "vue";
import { Select, Input, Radio } from "@/components/input-elements";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Apollo, Notify } from "@/core/services";
import { GET_TEMPLATES, GET_CARRIERS } from "@/modules/system/shipping-methods/graphql/Queries";
import InnerLoader from "../../../../../components/InnerLoader.vue";

export default defineComponent({
	name: "add new shipping method",
	components: {
		Input,
		Select,
		InnerLoader,
		Radio
	},

	setup(props, context) {
		const emitter: any = inject("emitter");
		const integrationFormRef = ref<null | HTMLFormElement>(null);
		const store = useStore();
		const i18n = useI18n();
        const pickups = ref([]) as Record<any, any>;
		const loader = ref(false);
		const carriers = ref([]) as Record<any, any>;
		const shippingMethod = ref([
			{label: "Shipmondo", value: 'SHIPMONDO'}
		]) as Record<any, any>;
		const templates = ref([]) as Record<any, any>;
		const types = ref([
			{label: "Service Point", value: 'SERVICE_POINT'},
			{label: "Pickup", value: 'PICKUP'}
		]) as Record<any, any>;
			
		// Given Shipping Form Interface
		const integrationFormData = ref({
			carriers: '',
			shipping_method: '',
			templates: '',
			types: '',
            address: 'Mesterlodden 42, 2820 Gentofte',
			is_pickup: 0,
			template_data: ''
		}) as any;

		pickups.value = [
            {
                label: 'message.YES',
                value: 1
            },
            {
                label: 'message.NO',
                value: 0
            }
        ];

		const getCarriers = async () => {
			const template_data = templates.value.find(template => template.value === integrationFormData.value.templates)
			if (template_data) {
				loader.value = true;
				await Apollo.watchQuery({
					query: GET_CARRIERS,
					errorPolicy: "all",
					fetchPolicy: "network-only",
					nextFetchPolicy: "cache-only",
					variables: {
						receiver_country_code: template_data.receiver_country_code
					}
				}).subscribe(({ data, errors }) => {
					if (errors) {
						loader.value = false;
					}
					carriers.value = [];
					const carriers_data = JSON.parse(data.carriers)
					carriers_data.forEach((element) => {
						carriers.value.push({
							label: element.name,
							value: element.code,
							id: element.id,
						});
					});
					loader.value = false;
				});
			}
		};

		const getTemplates = async () => {
			if(integrationFormData.value.shipping_method) {
				loader.value = true;
				await Apollo.watchQuery({
					query: GET_TEMPLATES,
					errorPolicy: "all",
					fetchPolicy: "network-only",
					nextFetchPolicy: "cache-only",
				}).subscribe(async ({ data, errors }) => {
					if (errors) {
						loader.value = false;
					}
					templates.value = [];
					const templates_data = JSON.parse(data.templates)
					templates_data.forEach((element) => {
						templates.value.push({
							label: element.name,
							receiver_country_code: element.receiver.country_code,
							value: element.id,
							data: JSON.stringify(element)
						});
					});
					loader.value = false;
					await getCarriers()
				});
			} else {
				integrationFormData.value.templates = ''
				integrationFormData.value.carriers = ''
				integrationFormData.value.types = ''
				integrationFormData.value.is_pickup = 0
			}
		};

		const getTypes = () => {
			if(integrationFormData.value.templates) {
				const template_data = templates.value.find(template => template.value === integrationFormData.value.templates)
				integrationFormData.value.template_data = template_data.data
				if(template_data.label == 'Service Point') {
					integrationFormData.value.types = 'SERVICE_POINT'
				} else if(template_data.label == 'Pickup') {
					integrationFormData.value.types = 'PICKUP'
				} else {
					integrationFormData.value.types = ''
				}
				getCarriers()
			} else {
				integrationFormData.value.carriers = ''
				integrationFormData.value.types = ''
				integrationFormData.value.is_pickup = 0
			}
		}

		const changePickUp = () => {
            if(integrationFormData.value.is_pickup == 0) {
				integrationFormData.value.carriers = ''
				integrationFormData.value.types = ''
			}
        };

		const validateForm = () => {
			let valid = true
			integrationFormRef.value?.validate(valid => {
				valid = true
            });

			if(integrationFormRef.value?.model.shipping_method != '' && integrationFormRef.value?.model.templates == '') {
				valid = false
			}
			if(integrationFormRef.value?.model.types == 'SERVICE_POINT' && integrationFormRef.value?.model.carriers == '' && integrationFormRef.value?.model.is_pickup == 1) {
				valid = false
			}
			
			if (valid) {
				context.emit("store", "integrationFormData", integrationFormData.value)
				return true;
			}
			return false;
		};

		const resetForm = () => {
			emitter.emit("clearInput");
			integrationFormRef.value?.resetFields();
		};

		const editShippingMethodValue = (shippingData: Record<any, any>) => {
			resetForm();
			integrationFormData.value.carriers = shippingData?.shipping_method_integration?.shipping_method_service_point_carrier?.carrier
			integrationFormData.value.shipping_method = shippingData?.shipping_method_integration?.shipping_method
			integrationFormData.value.templates = shippingData?.shipping_method_integration?.template_id
			integrationFormData.value.types = shippingData?.shipping_method_integration?.type
			integrationFormData.value.is_pickup = shippingData?.shipping_method_integration?.is_pickup ? shippingData.shipping_method_integration.is_pickup : 0
			integrationFormData.value.template_data = shippingData?.shipping_method_integration?.template_data
			if(shippingData?.shipping_method_integration?.shipping_method) {
				getTemplates()
			}
		};

		return {
			integrationFormData,
			integrationFormRef,
			carriers,
			shippingMethod,
			templates,
			types,
			pickups,
			loader,
			editShippingMethodValue,
			validateForm,
            changePickUp,
			getTemplates,
			getCarriers,
			getTypes
		};
	},
});
