import { gql } from "graphql-tag";
import { POST_CODE_FIELDS, PAGINATION } from "./Fragments";

export const GET_POST_CODES = gql`

    query GetPostCodes($page: Int, $limit: Int) {
        get_post_codes(limit:$limit, page:$page){
            ...Pagination
            data {
                ...PostCodeFields
            }
        }
    }
    ${POST_CODE_FIELDS},
    ${PAGINATION},

`;

export const SEARCH_POST_CODES = gql`

    query SearchPostCodes($page: Int, $limit: Int, $search_key: String, $locale:String  , $type:String , $date_from:String , $date_to:String) {
        search_post_codes(limit:$limit, page:$page, search_key:$search_key, locale:$locale , type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...PostCodeFields
            }
        }
    }
    ${POST_CODE_FIELDS},
    ${PAGINATION},

`;

export default { GET_POST_CODES, SEARCH_POST_CODES };
