import { gql } from 'graphql-tag';
import {
    CUSTOMER_FIELDS,
    PAGINATION,
    PAGINATION_ORDER,
    GET_CUSTOMER_ORDERS,
    CUSTOMER_USER_FIELDS,
    CUSTOMER_INFO_DETAILS_FIELDS,
    ACTIVE_CART_PRODUCTS_FIELDS,
    ACTIVE_CART_PAGINATION
} from './Fragments';

export const ACTIVE_CART = gql`
    query ActiveCart($page: Int, $limit: Int , $customer_id: Int) {
        active_cart(limit:$limit, page:$page , customer_id:$customer_id){
            ...ActiveCartPagination
            data {
                ...ActiveCartFields
            }
        }
    }
    ${ACTIVE_CART_PRODUCTS_FIELDS},
    ${ACTIVE_CART_PAGINATION},
`;

export const GET_CUSTOMER = gql`
    query GetCustomer($page: Int, $limit: Int) {
        customers(limit:$limit, page:$page){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_FIELDS},
    ${PAGINATION},
`;

export const GET_CUSTOMER_DROPDOWN = gql`
    query GetCustomerDropdown($page: Int, $limit: Int) {
        customers_dropdown(limit:$limit, page:$page){
            ...Pagination
            data {
                id
                user {
                    id
                    name
                }
            }
        }
    }
    ${PAGINATION},
`;

export const GET_CUSTOMER_DROPDOWN_FILTER = gql`
    query GetCustomerDropdownFilter($page: Int, $limit: Int , $origin:Int) {
        customers_dropdown_filter(limit:$limit, page:$page , origin:$origin){
            ...Pagination
            data {
                id
                user {
                    id
                    name
                }
            }
        }
    }
    ${PAGINATION},
`;

export const SEARCH_CUSTOMER = gql`
    query SearchCustomer($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $types:String , $date_from:String , $date_to:String, $col: String, $type: String) {
        search_customers(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, types:$types , date_from:$date_from , date_to:$date_to,col:$col, type:$type){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_FIELDS},
    ${PAGINATION},
`;

export const GET_CUSTOMER_USER = gql`
    query GetCustomerUser($page: Int, $limit: Int, $uuid:String) {
        customer_users(limit:$limit, page:$page, customer_uuid:$uuid){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_USER_FIELDS},
    ${PAGINATION},
`;

export const EDIT_CUSTOMER = gql`
    query EditCustomer($uuid: String!) {
        edit_customer(uuid: $uuid)
    }
`;

export const CUSTOMER_ORDER = gql`
    query CustomerOrder($page: Int, $limit: Int, $uuid: String!) {
        customer_orders(limit:$limit, page:$page, uuid: $uuid){
            ...Pagination
            data {
                ...OrderFields
            }
        }
    }
    ${GET_CUSTOMER_ORDERS},
    ${PAGINATION_ORDER},
`;

export const GET_CUSTOMER_INFO_DETAIL = gql`
    query GetCustomerInfo($page: Int, $limit: Int, $uuid: String! ) {
        customer_info(limit:$limit, page:$page, uuid:$uuid){
            ...CustomerFields
        }
    }
    ${CUSTOMER_INFO_DETAILS_FIELDS},
`;

export const GET_WIDGETS = gql`
    query GetWidgets($uuid: String!) {
        customer_widgets(uuid: $uuid)
    }
`;

export const CUSTOMER_DAILY_REVENUE = gql`
    query DailyRevenue($uuid: String!) {
        customer_daily_revenue(uuid: $uuid)
    }
`;

export const CUSTOMER_MONTHLY_REVENUE = gql`
    query MonthlyRevenue($uuid: String!) {
        customer_monthly_revenue(uuid: $uuid)
    }
`;

export const LOGIN_AS = gql`
    query LoginAs($uuid: String) {
        login_as(uuid: $uuid)
    }
`;

export default { GET_CUSTOMER_DROPDOWN,GET_CUSTOMER_DROPDOWN_FILTER, LOGIN_AS, GET_CUSTOMER, EDIT_CUSTOMER, CUSTOMER_ORDER, GET_CUSTOMER_USER, GET_CUSTOMER_INFO_DETAIL, SEARCH_CUSTOMER, GET_WIDGETS, CUSTOMER_DAILY_REVENUE, CUSTOMER_MONTHLY_REVENUE };
