
import { defineComponent, ref, inject, onMounted } from 'vue';
import { Select, Radio } from '@/components/input-elements';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Apollo, Notify } from '@/core/services';
import { GET_POST_CODES } from "@/modules/system/post-codes/graphql/Queries";
import InnerLoader from '../../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'add new shipping method',
    components: {
        Radio,
        Select,
        InnerLoader
    },

    setup(props, context) {
        const emitter: any = inject('emitter');
        const postCodeFormRef = ref<null | HTMLFormElement>(null);
        const postCodes = ref([]) as Record<any, any>;
        const postCodeShippingLabel = ref([]) as Record<any, any>;
        const store = useStore();
        const i18n = useI18n();
        const optionRule = ref([]) as Record<any, any>;
        const cartRuleOption = ref([]) as Record<any, any>;
        const loader = ref(false);
        const postCodeRules = ref([]) as Record<any, any>;
        const postCodeRule = ref([]) as Record<any, any>;
        const postCodeCartOption = ref([]) as Record<any, any>;

        // Given Shipping Form Interface
        const postCodeFormData = ref({
            rule: [],
            cartRule: [],
            post_code_cost_no_rule: [],
            post_code_tax_no_rule: [],
            post_codes: [],
            post_code_cart_rules: [],
            post_code_cart_rules2: [],
            post_code_cart_cost: [],
            post_code_cart_cost2: [],
            post_code_tax: [],
            post_code_tax2: [],
            cartRulesData: {},
            noRuleCost: {}
        }) as any;

        cartRuleOption.value = [
            {
                label: 'message.WEIGHT message.RULE',
                value: 0,
                default: 0
            },
            {
                label: 'message.TOTAL message.RULE',
                value: 1,
                default: 0
            }
        ];

        optionRule.value = [
            {
                label: 'message.NO message.RULE',
                value: 0
            },
            {
                label: 'message.RULE',
                value: 1
            }
        ];

        const getpostCodeShippingRuleLabel = e => {
            e.forEach((element, index) => {
                if (element == 1) {
                    postCodeShippingLabel.value[index] = `${i18n.t('message.TOTAL')} ${i18n.t('message.RULE')}`;
                } else {
                    postCodeShippingLabel.value[index] = `${i18n.t('message.WEIGHT')} ${i18n.t('message.RULE')}`;
                }
            });
        };

        const getpostCodes = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_POST_CODES,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                postCodes.value = [];
                data.get_post_codes.data.forEach(element => {
                    postCodes.value.push({
                        label: element.from + ' - ' + element.to,
                        value: element.id
                    });
                });
                loader.value = false;
            });
        };

        onMounted(() => {
            getpostCodes();
        });

        const postCode = (groups: Record<any, any>) => {
            
            let validate = false
            
            const selected_post_code = postCodeFormData.value.post_codes.find(ele => ele == groups[Object.entries(groups).length-1]);
            const selected_post_codes = postCodes.value.find(ele => ele.value == selected_post_code);
            const selected_post_code_label = selected_post_codes.label.split(" - ")
            const selected_from = selected_post_code_label[0]
            const selected_to = selected_post_code_label[1]
            
            for (let i = 0; i < Object.entries(groups).length - 1; i++) {
                const post_code = postCodeFormData.value.post_codes.find(ele => ele == groups[i]);
                const post_codes = postCodes.value.find(ele => ele.value == post_code);
                const post_code_label = post_codes.label.split(" - ")
                const from = post_code_label[0]
                const to = post_code_label[1]

                if(from <= selected_from && to >= selected_from || from <= selected_to && to >= selected_to || selected_from <= from && selected_to >= from || selected_from <= to && selected_to >= to) {
                    validate = true
                }
            }
            
            if(validate) {
                postCodeFormData.value.post_codes.splice(Object.entries(groups).length-1,1)
                Notify.error(`${i18n.t('message.POST_CODE_ALREADY_EXIST')}`);
            }
            else
            {
                if (Object.entries(groups).length != 0) {
                    postCodeRules.value = [];
                    groups.forEach(element => {
                        const post_codes = postCodes.value.find(ele => ele.value == element);
                        postCodeRules.value.push({
                            relation: 'post-code',
                            data: post_codes
                        });
                    });
                } else {
                    postCodeRules.value = [];
                }
            }
        };

        const validateForm = () => {
            postCodeFormRef.value?.validate(valid => {
                if (valid) {
                    const shippingpostCodeName = [] as Record<any, any>;
                    for (let i = 0; i < postCodeRules.value.length; i++) {
                        if (postCodeRule.value[postCodeRules.value[i].data.value] == 1) {
                            if (
                                typeof postCodeFormData.value.post_code_cart_rules[postCodeRules.value[i].data.value] === 'undefined' ||
                                postCodeFormData.value.post_code_cart_rules[postCodeRules.value[i].data.value] == '' ||
                                typeof postCodeFormData.value.post_code_cart_rules2[postCodeRules.value[i].data.value] === 'undefined' ||
                                postCodeFormData.value.post_code_cart_rules2[postCodeRules.value[i].data.value] == '' ||
                                typeof postCodeFormData.value.post_code_cart_cost[postCodeRules.value[i].data.value] === 'undefined' ||
                                postCodeFormData.value.post_code_cart_cost[postCodeRules.value[i].data.value] == '' ||
                                typeof postCodeFormData.value.post_code_cart_cost2[postCodeRules.value[i].data.value] === 'undefined' ||
                                postCodeFormData.value.post_code_cart_cost2[postCodeRules.value[i].data.value] == ''
                            ) {
                                shippingpostCodeName.push(postCodeRules.value[i].data.label);
                            }
                        } else if (
                            typeof postCodeFormData.value.post_code_cost_no_rule[postCodeRules.value[i].data.value] === 'undefined' ||
                            postCodeFormData.value.post_code_cost_no_rule[postCodeRules.value[i].data.value] == ''
                        ) {
                            shippingpostCodeName.push(postCodeRules.value[i].data.label);
                        }
                    }
                    if (shippingpostCodeName.length > 0) {
                        Notify.error(`${i18n.t('message.ENTER_RULES_AND_PRICE_FOR_SELECTED')} ${shippingpostCodeName.join(' , ')} ${i18n.t('message.SHIPPING')}`);
                        return;
                    }

                    const cartRulesData = {
                        post_codes: {
                            greater_condition: {
                                rules: postCodeFormData.value.post_code_cart_rules,
                                cost: postCodeFormData.value.post_code_cart_cost,
                                tax: postCodeFormData.value.post_code_tax
                            },
                            less_condition: {
                                rules: postCodeFormData.value.post_code_cart_rules2,
                                cost: postCodeFormData.value.post_code_cart_cost2,
                                tax: postCodeFormData.value.post_code_tax2
                            }
                        }
                    };

                    postCodeFormData.value.cartRulesData = cartRulesData;

                    const noRuleCost = {
                        post_code_cost: postCodeFormData.value.post_code_cost_no_rule,
                        post_code_tax: postCodeFormData.value.post_code_tax_no_rule
                    };

                    postCodeFormData.value.noRuleCost = noRuleCost;
                    postCodeFormData.value.rule = postCodeRule.value;
                    postCodeFormData.value.cartRule = postCodeCartOption.value;

                    context.emit('store', 'postCodeFormData', postCodeFormData.value);
                    return true;
                }

                return false;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            postCodeFormRef.value?.resetFields();
        };

        const editShippingMethodValue = (shippingData: Record<any, any>) => {
            resetForm();
            if (shippingData.post_codes.length > 0) {
                shippingData.post_codes.forEach(element => {
                    postCodeFormData.value.post_codes.push(element.id);
                    postCodeRules.value.push({
                        relation: 'post-code',
                        data: { label: element.from + '-' + element.to, value: element.id }
                    });
                    shippingData.shipping_rule.forEach(ele => {
                        if (ele.relation == 'post-code' && ele.relation_id == element.id) {
                            switch (ele.condition) {
                                case '==':
                                    postCodeRule.value[element.id] = 0;
                                    postCodeFormData.value.post_code_cost_no_rule[element.id] = ele.shipping_cost.cost;
                                    postCodeFormData.value.post_code_tax_no_rule[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    break;
                                case '>=':
                                    postCodeRule.value[element.id] = 1;
                                    postCodeCartOption.value[element.id] = ele.rule == 'total' ? 1 : 0;
                                    postCodeShippingLabel.value[element.id] = postCodeCartOption.value[element.id] == 1 ? 'Total Rule' : 'Weight Rule';
                                    postCodeFormData.value.post_code_cart_cost[element.id] = ele.shipping_cost.cost;
                                    postCodeFormData.value.post_code_tax[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    postCodeFormData.value.post_code_cart_rules[element.id] = ele.cart_rule_value;
                                    break;
                                case '<':
                                    postCodeFormData.value.post_code_cart_cost2[element.id] = ele.shipping_cost.cost;
                                    postCodeFormData.value.post_code_tax2[element.id] = ele.shipping_cost.taxable == 0 ? false : true;
                                    postCodeFormData.value.post_code_cart_rules2[element.id] = ele.cart_rule_value;
                                    break;
                            }
                        }
                    });
                });
            }
        };

        return {
            postCodeFormData,
            postCodes,
            optionRule,
            cartRuleOption,
            postCodeFormRef,
            postCodeRules,
            postCodeRule,
            postCodeCartOption,
            postCodeShippingLabel,
            loader,
            editShippingMethodValue,
            validateForm,
            getpostCodeShippingRuleLabel,
            postCode
        };
    }
});
