import { gql } from "graphql-tag";

export const SHIPPING_METHODS_FIELDS = gql`
  fragment ShippingMethodFields on ShippingMethod {
    id
    uuid
    name
    path
    status
    position
    image  {
      id
      file_path
    }
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on ShippingMethodPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { SHIPPING_METHODS_FIELDS, PAGINATION };