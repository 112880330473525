import { gql } from "graphql-tag";

export const POST_CODE_FIELDS = gql`
	fragment PostCodeFields on PostCode {
		id
		from
		to
		path
		created_at
	}
`;

export const PAGINATION = gql`
	fragment Pagination on PostCodePagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { POST_CODE_FIELDS, PAGINATION };
