import { gql } from "graphql-tag";
import { SHIPPING_METHODS_FIELDS, PAGINATION } from "./Fragments";

export const GET_SHIPPING_METHODS= gql`
    query GetShippingMethod($page: Int, $limit: Int) {
        shipping_methods(limit:$limit, page:$page){
            ...Pagination
            data {
                ...ShippingMethodFields
            }
        }
    }
    ${SHIPPING_METHODS_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_SHIPPING_METHODS= gql`
    query SearchShippingMethod($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_shipping_methods(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...ShippingMethodFields
            }
        }
    }
    ${SHIPPING_METHODS_FIELDS},
    ${PAGINATION},
`;


export const EDIT_SHIPPING_METHOD= gql`
    query EditShippingMethod($shipping_method_id: String) {
        edit_shipping_method(shipping_method_id:$shipping_method_id)
        }
`;

export const GET_TEMPLATES= gql`
    query GetTemplates {
        templates
    }
`;

export const GET_CARRIERS= gql`
    query GetCarriers($receiver_country_code: String) {
        carriers(receiver_country_code:$receiver_country_code)
    }
`;

export default { GET_SHIPPING_METHODS, EDIT_SHIPPING_METHOD, SEARCH_SHIPPING_METHODS, GET_TEMPLATES, GET_CARRIERS };